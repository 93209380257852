header .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
}

header .banner-text {
  width: 100%;
}
header .banner-text h1 {
  font-size: 4rem;
  font-weight: 500;
  color: #fff;
  letter-spacing: -2px;
  margin: 0 auto 30px auto;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}
header .banner-text h3 {
  font-size: 1.1rem;
  font-weight: 400;
  color: #a8a8a8;
  margin: 0 auto;
  width: 70%;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}
header .banner-text h3 span,
header .banner-text h3 a {
  color: #fff;
}
header .banner-text hr {
  width: 60%;
  margin: 18px auto 24px auto;
  border-color: #2f2d2e;
  border-color: rgba(150, 150, 150, 0.1);
}

/* screenwidth less than 1024px
--------------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {
  /* header styles
 ------------------------------------------------------------------ */
  header .banner-text h1 {
    font-size: 80px/1.1em;
    letter-spacing: -1px;
    margin: 0 auto 12px auto;
  }
}

/* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
  /* header styles
 ------------------------------------------------------------------ */
  header .banner {
    padding-bottom: 12px;
  }
  header .banner-text h1 {
    font-size: 78px/1.1em;
    letter-spacing: -1px;
  }
  header .banner-text h3 {
    font-size: 17px/1.9em;
    width: 80%;
  }
  header .banner-text hr {
    width: 65%;
    margin: 12px auto;
  }
}

/* mobile narrow
-------------------------------------------------------------------------- */

@media only screen and (max-width: 480px) {
  /* mobile navigation
 -------------------------------------------------------------------- */
  #nav-wrap ul#nav {
    width: auto;
    float: none;
  }

  /* header styles
 -------------------------------------------------------------------- */
  header .banner {
    padding-top: 24px;
  }
  header .banner-text h1 {
    font-size: 2rem;
    font-weight: 500;
    margin: 0 auto 24px auto;
  }
  header .banner-text h3 {
    font-size: 14px/1.9em;
    width: 90%;
  }
}
