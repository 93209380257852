/* header social links */
header .social {
  margin: 24px 0;
  padding: 0;
  font-size: 30px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}
header .social li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}
header .social li a {
  color: #fff;
}
header .social li a:hover {
  color: #008ad7;
}

/* social links */
footer .social {
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 30px;
}
footer .social li {
  display: inline-block;
  font-size: 30px;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #f06000;
}

footer .social li:first-child {
  margin-left: 0;
}

/* social links */
footer .social-links {
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 30px;
}
footer .social-links li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #f06000;
}

footer .social-links li:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  /* header social links */
  header .social {
    margin: 18px 0 24px 0;
    font-size: 24px;
    line-height: 36px;
  }
  header .social li {
    margin: 0 10px;
  }

  /* social links */
  footer .social {
    font-size: 22px;
  }
  footer .social li {
    margin-left: 18px;
  }
}

@media only screen and (max-width: 480px) {
  /* header social links */
  header .social {
    font-size: 20px;
  }
  header .social li {
    margin: 0 6px;
  }

  /* social links */
  footer .social {
    font-size: 20px;
  }
  footer .social li {
    margin-left: 14px;
  }
}
