#nav-wrap ul,
#nav-wrap li,
#nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

/* nav-wrap */
#nav-wrap {
  font-size: 12px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
}

/* hide toggle button */
#nav-wrap > a.mobile-btn {
  display: none;
}

/* nav-wrap */
#nav-wrap {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.5px;
}

@media only screen and (max-width: 767px) {
  #nav-wrap {
    font-size: 12px;
    background: transparent !important;
    letter-spacing: 1.5px;
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
  }
  #nav-wrap > a {
    width: 48px;
    height: 48px;
    text-align: left;
    background-color: #008ad7;
    position: relative;
    border: none;
    float: right;

    font: 0/0 a;
    text-shadow: none;
    color: transparent;

    position: relative;
    top: 0px;
    right: 30px;
  }

  #nav-wrap > a:before,
  #nav-wrap > a:after {
    position: absolute;
    border: 2px solid #fff;
    top: 35%;
    left: 25%;
    right: 25%;
    content: "";
  }
  #nav-wrap > a:after {
    top: 60%;
  }

  /* toggle buttons */
  #nav-wrap:not(:target) > a:first-of-type,
  #nav-wrap:target > a:last-of-type {
    display: block;
  }

  /* hide menu panel */
  #nav-wrap ul#nav {
    height: auto;
    display: none;
    clear: both;
    width: auto;
    float: right;

    position: relative;
    top: 12px;
    right: 0;
  }

  /* display menu panels */
  #nav-wrap:target > ul#nav {
    display: block;
    padding: 30px 20px 48px 20px;
    background: #1f2024;
    margin: 0 30px;
    clear: both;
  }
}

@media only screen and (max-width: 480px) {
  #nav-wrap ul#nav {
    width: auto;
    float: none;
  }
}

ul#nav {
  min-height: 48px;
  width: auto;

  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}

/* Links */
ul#nav li a {
  /* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding: 8px 13px;
  line-height: 32px;
  text-decoration: none;
  text-align: left;
  color: #fff;

  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

ul#nav li a:active {
  background-color: transparent !important;
}
ul#nav li.current a {
  color: #008ad7;
}

ul#nav li a:hover {
  color: #1aa4f1;
}
